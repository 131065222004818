// src/components/FareharborScript.js

import React from 'react';
import { Script } from 'gatsby';

const RegiondoScript = () => (
    <Script type="text/javascript" src="https://widgets.regiondo.net/catalog/v1/catalog-widget.min.js">
    </Script>
);

export default RegiondoScript;

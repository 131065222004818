import React from 'react';

const LocatorPlusIframe = ({ src }) => {
  return (
    <iframe
      src={src}
      width="100%"
      height="600px"
      style={{ border: '0' }}
      loading="lazy"
    ></iframe>
  );
};

export default LocatorPlusIframe;

import { graphql } from 'gatsby';

import { StructuredText } from 'react-datocms';

import { PageWrapper } from '../components/Layout/PageWrapper';
import { Hero } from '../components/Layout/Hero';
import { Navigator } from '../components/Navigator';
import { RichText } from '../components/Layout/RichText';
import {
  Section,
  SectionGridThreeCols,
  SectionFlexTwoCols,
  SectionFlexTwoColsReverse,
  ColumnFlex,
  GridTextBox,
  Button
} from '../components/Layout/sharedStyles/sectionStyles';
import {
  HeadingMedium,
  HeadingSmallWithTip,
} from '../components/Layout/sharedStyles/headingStyles';
import Collapsible from 'react-collapsible';
import LocatorPlusIframe from '../components/Layout/GoogleMapsEmbed';
import RegiondoScript from '../components/RegiondoScript';


const OtherPagesTemplate = ({
  data: {
    datoCmsOtherPage: { seo, structuredBody, widgetId },
  },
  pageContext
}) => (
  <PageWrapper
    pageData={pageContext}
    seoTitle={seo?.seoTitle}
    seoDescription={seo?.seoDescription}
    seoImage={seo?.seoImage?.seoImageUrl}
  >
    {structuredBody?.value && (
      <StructuredText
        data={structuredBody}
        renderBlock={({
          record: {
            __typename,
            heroAlt,
            heroTitle,
            heroSubtitle,
            image,
            title,
            text,
            firstFeatureTitle,
            firstFeatureDescription,
            secondFeatureTitle,
            secondFeatureDescription,
            thirdFeatureTitle,
            thirdFeatureDescription,
            label,
            link,
            collapsible,
            googlemapsurl
          },
        }) => {
          switch (__typename) {
            case 'DatoCmsHero':
              return (
                <Hero
                  caption={heroAlt}
                  title={heroTitle}
                  subtitle={heroSubtitle}
                />
              );
            case 'DatoCmsGooglemapsurl':
              return (
                <LocatorPlusIframe src={googlemapsurl} />
              );
            case 'DatoCmsSectionTextOnly':
              return (
                <SectionFlexTwoCols>
                  {/* <ColumnFlex> */}
                  <GridTextBox as="div">
                    {collapsible ?
                      <Collapsible trigger={title} triggerClassName='classicButton classicButtonOutline' triggerOpenedClassName='classicButton classicButtonOutline'>
                        <RichText as="div">
                          <StructuredText
                            data={text}
                            renderLinkToRecord={({
                              record: { id },
                              children,
                              transformedMeta,
                            }) => (
                              <Navigator {...transformedMeta} recordId={id}>
                                {children}
                              </Navigator>
                            )}
                          />
                        </RichText>
                      </Collapsible>
                      : <div>
                        <HeadingMedium>{title}</HeadingMedium>
                        <RichText as="div">
                          <StructuredText
                            data={text}
                            renderLinkToRecord={({
                              record: { id },
                              children,
                              transformedMeta,
                            }) => (
                              <Navigator {...transformedMeta} recordId={id}>
                                {children}
                              </Navigator>
                            )}
                          />
                        </RichText>
                      </div>}
                  </GridTextBox>
                  {/* </ColumnFlex> */}
                </SectionFlexTwoCols>
              );
            case 'DatoCmsSectionImageLeft':
              return (
                <SectionFlexTwoCols>
                  <ColumnFlex hasImg>
                    <img src={image.url} alt={image.alt} />
                  </ColumnFlex>
                  <ColumnFlex>
                    <GridTextBox as="div">
                      {collapsible ?
                        <Collapsible trigger={title} triggerClassName='classicButton classicButtonOutline right' triggerOpenedClassName='classicButton classicButtonOutline'>
                          <RichText as="div">
                            <StructuredText
                              data={text}
                              renderLinkToRecord={({
                                record: { id },
                                children,
                                transformedMeta,
                              }) => (
                                <Navigator {...transformedMeta} recordId={id}>
                                  {children}
                                </Navigator>
                              )}
                            />
                          </RichText>
                        </Collapsible>
                        : <div>
                          <HeadingMedium>{title}</HeadingMedium>
                          <RichText as="div">
                            <StructuredText
                              data={text}
                              renderLinkToRecord={({
                                record: { id },
                                children,
                                transformedMeta,
                              }) => (
                                <Navigator {...transformedMeta} recordId={id}>
                                  {children}
                                </Navigator>
                              )}
                            />
                          </RichText>
                        </div>}

                    </GridTextBox>
                  </ColumnFlex>
                </SectionFlexTwoCols>
              );
            case 'DatoCmsSectionImageRight':
              return (
                <SectionFlexTwoColsReverse>
                  <ColumnFlex>
                    <GridTextBox as="div">
                      {collapsible ?
                        <Collapsible trigger={title} triggerClassName='classicButton classicButtonOutline right' triggerOpenedClassName='classicButton classicButtonOutline'>
                          <RichText as="div">
                            <StructuredText
                              data={text}
                              renderLinkToRecord={({
                                record: { id },
                                children,
                                transformedMeta,
                              }) => (
                                <Navigator {...transformedMeta} recordId={id}>
                                  {children}
                                </Navigator>
                              )}
                            />
                          </RichText>
                        </Collapsible>
                        : <div>
                          <HeadingMedium>{title}</HeadingMedium>
                          <RichText as="div">
                            <StructuredText
                              data={text}
                              renderLinkToRecord={({
                                record: { id },
                                children,
                                transformedMeta,
                              }) => (
                                <Navigator {...transformedMeta} recordId={id}>
                                  {children}
                                </Navigator>
                              )}
                            />
                          </RichText>
                        </div>}
                    </GridTextBox>
                  </ColumnFlex>
                  <ColumnFlex hasImg>
                    <img src={image.url} alt={image.alt} />
                  </ColumnFlex>
                </SectionFlexTwoColsReverse>
              );
            case 'DatoCmsThreeFeaturesSet':
              return (
                <SectionGridThreeCols>
                  <GridTextBox small>
                    <HeadingSmallWithTip>
                      {firstFeatureTitle}
                    </HeadingSmallWithTip>
                    <RichText>{firstFeatureDescription}</RichText>
                  </GridTextBox>
                  <GridTextBox small>
                    <HeadingSmallWithTip>
                      {secondFeatureTitle}
                    </HeadingSmallWithTip>
                    <RichText>{secondFeatureDescription}</RichText>
                  </GridTextBox>
                  <GridTextBox small>
                    <HeadingSmallWithTip>
                      {thirdFeatureTitle}
                    </HeadingSmallWithTip>
                    <RichText>{thirdFeatureDescription}</RichText>
                  </GridTextBox>
                </SectionGridThreeCols>
              );
            case 'DatoCmsButton':
              return (
                <a className='classicButton classicButtonOutline float button-booking rezdy rezdy-modal otherpagesfloat' href={link}>
                  {label}
                </a>
              );
            default:
              return null;
          }
        }}
      />
    )}
    <product-catalog-widget widget-id={widgetId}></product-catalog-widget>
    <RegiondoScript/>
  </PageWrapper>
);

export default OtherPagesTemplate;

export const query = graphql`
query OtherPagesQuery($locale: String!, $id: String!) {
  datoCmsOtherPage(locale: {eq: $locale}, originalId: {eq: $id}) {
    locale
    structuredBody {
      value
      blocks {
        ... on DatoCmsHero {
          id: originalId
          __typename
          heroTitle
          heroSubtitle
          heroAlt
        }
        ... on DatoCmsSectionTextOnly {
          __typename
          id: originalId
          title
          collapsible
          text {
            value
            links {
              ... on DatoCmsOtherPage {
                id: originalId
              }
              ... on DatoCmsHomepage {
                id: originalId
              }
            }
          }
        }
        ... on DatoCmsSectionImageLeft {
          __typename
          id: originalId
          title
          collapsible
          image {
            url
            gatsbyImageData
            alt
          }
          text {
            value
            links {
              ... on DatoCmsOtherPage {
                id: originalId
              }
              ... on DatoCmsHomepage {
                id: originalId
              }
            }
          }
        }
        ... on DatoCmsSectionImageRight {
          __typename
          id: originalId
          title
          collapsible
          image {
            url
            gatsbyImageData
            alt
          }
          text {
            value
            links {
              ... on DatoCmsOtherPage {
                id: originalId
              }
              ... on DatoCmsHomepage {
                id: originalId
              }
            }
          }
        }
        ... on DatoCmsThreeFeaturesSet {
          __typename
          id: originalId
          firstFeatureTitle
          firstFeatureDescription
          secondFeatureTitle
          secondFeatureDescription
          thirdFeatureTitle
          thirdFeatureDescription
        }
        ... on DatoCmsButton {
          __typename
          id: originalId
          label
          link
        }
        ... on DatoCmsGooglemapsurl {
          __typename
          id: originalId
          googlemapsurl
        }
      }
      }
      widgetId
  }
}
`;
